import "../Styles/Traiteur.css"
import "../Styles/MenuduSoir.css"
import "../Styles/Module.css";
import Exterieur from '../Assets/Exterieur.jpg'
import Millefeuille from '../Assets/millefeuille.png'
import aemporter from '../Assets/aemporter.jpeg'

function Traiteur (){

    return (
        <div>
            <div className="traiteur_TakeAway">
                <div className="TextMenu_Traiteur"> -  Traiteur  - </div>
                <div className="Traiteur_Space">
                    <div className="Traiteur_Title">
                    </div>
                    <div className="">
                        <div className="traiteur_info_">
                            <div className="size ">
                                <span className="traiteur_info_title">Traiteur</span>
                                <div className="traiteur_intro">Chez Fanfan, c'est aussi un traiteur pour toutes vos occasions 
                                évènementielles (professionnels comme particuliers)
                                </div>
                            </div>

                            <div className="size ">
                                <span className="traiteur_info_title">Menu</span>
                                <div className="traiteur_intro"> 
                                    Le menu traiteur est élaboré en fonction des produits de saison mais également en fonction de vos envies. 
                                    N'hésitez pas à nous contacter pour cela 
                                </div>
                            </div>

                            <div className="size traiteur_intro">
                                <span className="traiteur_info_title"> Buffet dinatoire </span> 
                                <span className="traiteur_buffet_text"><b>5</b> pièces salées,
                                    <span className="price_pics">12€/ personne</span>
                                </span>
                                <span className="price_space"> ou </span>
                                <span className="traiteur_buffet_text"><b>8</b> pièces (5 salées et 3 sucrées),
                                    <span className="price_pics"> 18€/ personne</span>
                                </span>
                            </div>
                            <div className="size ">
                                <span className="traiteur_info_title">Déplacement</span>
                                <div className="traiteur_intro"> 
                                    Nous pouvons nous déplacer lors de vos évènements si les lieux disposent de l'espace et parfois matériels utiles. 
                                    Une autre solution est que nous préparions au restaurant et que vous veniez récuperer les repas.
                                </div>
                            </div>
                        </div>
                        <div className="pics_place">
                            <div className="size box"><img className="traiteur_pics_size" src={Exterieur} alt="Exterieur"/></div>
                            <div className="size box"><img className="traiteur_pics_size"src={aemporter} alt="Salle"/></div>
                            <div className="size box"><img className="traiteur_pics_size"src={Millefeuille} alt="Plat Couteau et sa purée" /></div>
                        </div>
                    </div>
                    <div className="size price_infos">- Pour plus d'information contactez-nous au <b>05 61 42 53 96</b> -</div>
                </div>
            </div>
        </div>
    )

}

export default Traiteur